dmx.Component('bs5-offcanvas', {

  initialData: {
    open: false
  },

  attributes: {
    show: {
      type: Boolean,
      default: false
    },

    backdrop: {
      type: [Boolean, String],
      default: true
    },

    nokeyboard: {
      type: Boolean,
      default: false
    },

    scroll: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle () {
      requestAnimationFrame(() => this._instance.toggle());
    },

    show () {
      requestAnimationFrame(() => this._instance.show());
    },

    hide () {
      requestAnimationFrame(() => this._instance.hide());
    }
  },

  events: {
    show: Event,
    shown: Event,
    hide: Event,
    hidden: Event
  },

  init (node) {
    node.addEventListener('show.bs.offcanvas', this.dispatchEvent.bind(this, 'show'));
    node.addEventListener('shown.bs.offcanvas', this.dispatchEvent.bind(this, 'shown'));
    node.addEventListener('hide.bs.offcanvas', this.dispatchEvent.bind(this, 'hide'));
    node.addEventListener('hidden.bs.offcanvas', this.dispatchEvent.bind(this, 'hidden'));

    node.addEventListener('shown.bs.offcanvas', this._shownHandler.bind(this));
    node.addEventListener('hidden.bs.offcanvas', this._hiddenHandler.bind(this));

    node.classList.toggle('show', this.props.show);

    let backdrop = this.props.backdrop;
    if (backdrop != 'static') {
      backdrop = backdrop != 'false';
    }

    this._instance = new bootstrap.Offcanvas(node, {
      backdrop: backdrop,
      keyboard: !this.props.nokeyboard,
      scroll: this.props.scroll
    });
  },

  destroy () {
    this._instance.dispose();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('show')) {
      this.$node.classList.toggle('show', this.props.show);
      this.set('open', this.props.show);
    }
  },

  _shownHandler () {
    this.set('open', true);
  },

  _hiddenHandler () {
    this.set('open', false);
  },

});